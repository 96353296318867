<template>

  <div id="loaded" class="container main-container">
    <div class="px-1">
      <div class="header-section-main">
        <v-row class="ml-0 mr-0">
          <v-col class="pa-0" cols="4">
            <span class="header-line-1">Occupancy Statistics</span><br>
            <span class="header-line-2">{{ new Date() | formatLocalDateTimeLong(true) }}</span>
          </v-col>
          <v-col class="pa-0" cols="8">
            <div class="float-right facilityBox" ></div>
            <div class="text-right " style="line-height: 1.3;">
              <span class="header-line-3 mr-2" >L002 - Oakley Self Storage</span>
              <br>
              <span class="header-line-4 mr-2">4700 Main Street, Oakley CA 94561</span>
              <br>
              <span class="header-line-4 mr-2">
               (925) 625-7867
              </span>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="body-temp mt-2" v-for="section in reportData" :key="section.label">
        <v-row>
          <v-col cols="12" class="pb-0">
            <span style="font-weight:500;">Unit Type: {{section.category.name}}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <div class="space-type">
              <span>Spaces</span>
            </div>
              <table>
                <tr class="table-header">
                  <th style="width: 170px;" >Size</th>
                  <th style="width: 110px;" >Area (sq ft.)</th>
                  <th class="table-first-header" >Total Units</th>
                  <th class="table-first-header" >Total Area</th>
                  <th class="table-first-header" >Occupied</th>
                  <th class="table-first-header" >Vacant</th>
                  <th class="table-first-header" >Unrentable</th>
                </tr>
                <tr v-for="(size, i) in section.breakdown" :key="i">
                  <td>{{ size.label }}</td>
                  <td>{{ size.area | formatMoneyWithComma(0)}}</td>
                  <td>{{ size.total_units }}</td>
                  <td>{{ size.total_area | formatMoneyWithComma(0) }}</td>
                  <td>{{ size.occupied  }}</td>
                  <td>{{ size.vacant }}</td>
                  <td>{{ size.unrentable }}</td>
                </tr>
              </table>
          </v-col>
          <v-col class="px-0" cols="5">
            <div class="space-type">
              <span>Monthly Rent</span>
            </div>
            <table>
              <tr class="table-header">
                <th class="table-first-header" >Avg. Rate</th>
                <th class="table-first-header" >Gross Potential</th>
                <th class="table-first-header" >Gross Occupied</th>
                <th class="table-first-header" >Actual Occupied</th>
              </tr>
              <tr v-for="(size, i) in section.breakdown" :key="i">
                <td>{{ size.avg_rate | formatMoneyWithComma(2)}}</td>
                <td>{{ size.gross_potential | formatMoneyWithComma(0)}}</td>
                <td>{{ size.gross_occupied | formatMoneyWithComma(0)}}</td>
                <td>{{ size.actual_occupied | formatMoneyWithComma(0)}}</td>
              </tr>
            </table>
          </v-col>
          <v-col cols="3">
            <div class="space-type">
              <span>Occupancy</span>
            </div>
            <table>
              <tr class="table-header">
                <th class="table-first-header" >Income</th>
                <th class="table-first-header" >Space</th>
                <th class="table-first-header" >Area</th>
                <th class="table-first-header" >Economic Occupancy</th>
              </tr>
              <tr v-for="(size, i) in section.breakdown" :key="i">
                <td >{{ size.income_occupancy * 1e2 | formatMoneyWithComma(0)}}%</td>
                <td >{{ size.space_occupancy * 1e2 | formatMoneyWithComma(0)}}%</td>
                <td >{{ size.area_occupancy * 1e2 | formatMoneyWithComma(0)}}%</td>
                <td >{{ size.actual_occupied / size.gross_potential * 1e2| formatMoneyWithComma(0) }}%</td>
              </tr>
            </table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8" class="mx-auto pa-0">
            <v-row>
              <v-col cols="4">
                <table class="summary-grid">
                  <tr>
                    <td>Occupied Area</td>
                    <td>{{ section.summary.occupied_area | formatMoneyWithComma(2) }}</td>
                  </tr>
                  <tr>
                    <td>Vacant Area</td>
                    <td>{{ section.summary.vacant_area | formatMoneyWithComma(2) }}</td>
                  </tr>
                  <tr>
                    <td>Unrentable Area</td>
                    <td>{{ section.summary.unrentable_area | formatMoneyWithComma(2) }}</td>
                  </tr>
                  <tr style="font-weight:500;">
                    <td>Total Area</td>
                    <td>{{ section.summary.total_area | formatMoneyWithComma(2) }}</td>
                  </tr>
                </table>
              </v-col>
              <v-col cols="7">
                <table class="summary-grid">
                  <tr class="table-header" style="height: 26px;">
                    <th></th>
                    <th>Occupied</th>
                    <th>Vacant</th>
                    <th>Unrentable</th>
                    <th>Total</th>
                  </tr>
                  <tr>
                    <td>Average Area/Unit  </td>
                    <td>{{ section.summary.avg_area_unit_occupied |   formatMoneyWithComma(2) }}</td>
                    <td>{{ section.summary.avg_area_unit_vacant |   formatMoneyWithComma(2) }}</td>
                    <td>{{ section.summary.avg_area_unit_unrentable |   formatMoneyWithComma(2) }}</td>
                    <td>{{ ( +section.summary.avg_area_unit_occupied + +section.summary.avg_area_unit_vacant + +section.summary.avg_area_unit_unrentable ) / 3 |   formatMoneyWithComma(2) }}</td>
                  </tr>
                  <tr>
                    <td>Average Rent/Unit</td>
                    <td>{{ section.summary.avg_rent_unit_occupied |   formatMoneyWithComma(2) }}</td>
                    <td>{{ section.summary.avg_price_unit_vacant  |   formatMoneyWithComma(2)}}</td>
                    <td>{{ section.summary.avg_price_unit_unrentable |   formatMoneyWithComma(2) }}</td>
                    <td>{{ ( +section.summary.avg_rent_unit_occupied + +section.summary.avg_price_unit_vacant + +section.summary.avg_price_unit_unrentable ) / 3 |   formatMoneyWithComma(2) }}</td>
                  </tr>
                  <tr>
                    <td>Average Rent/Area</td>
                    <td>{{ section.summary.avg_rent_unit_occupied / section.summary.avg_area_unit_occupied |   formatMoneyWithComma(2)}}</td>
                    <td>{{ section.summary.avg_price_unit_vacant / section.summary.avg_area_unit_vacant   |   formatMoneyWithComma(2)}}</td>
                    <td>{{ section.summary.avg_price_unit_unrentable  / section.summary.avg_area_unit_unrentable  |   formatMoneyWithComma(2)}}</td>
                    <td>{{
                      (
                          (  section.summary.avg_rent_unit_occupied / section.summary.avg_area_unit_occupied ) +
                          ( section.summary.avg_price_unit_vacant / section.summary.avg_area_unit_vacant ) +
                          ( section.summary.avg_price_unit_unrentable / section.summary.avg_area_unit_unrentable  )
                      ) / 3 |   formatMoneyWithComma(2)
                      }}</td>
                  </tr>

                </table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <!-- <div class="footer-text">
        <span class="printed-text">Printed on {{ (new Date()) | formatLocalDateTimeLong }}  1/1</span>
      </div> -->
    </div>
  </div>
</template>

<script>
import api from '../../assets/api.js';
import moment from 'moment';
export default {
  data() {
    return {
        reportData: []
    }
  },
    computed: {
        facilityAddress() {
            let address = this.propertyData.Address
            let fullAddress = address.address + ', ' + address.city + ' ' +address.state + ' ' + address.zip
            if (fullAddress.length >= 70) {
                fullAddress = fullAddress.substring(0,70)
            }
            return fullAddress
        }
    },
    async created () {
        this.reportDate = this.$route.query.date,
        this.property_id = this.$route.query.property_id
        const token = this.$route.query.token

        if (this.reportDate &&  this.property_id  && token) {
            this.$store.dispatch('authenticationStore/setAuthenticated', {
                is_authenticated: true,
                auth_token: token
            })

            this.$store.dispatch('callStore/setPdfSupport', {
                downloadPdf: true
            })
        }

        this.dataLoading = false;
        await this.fetchData()
        await this.fetchProperty(this.property_id)

        this.$store.dispatch('callStore/setPdfSupport', {
            downloadPdf: false
        })

        this.dataLoading = true;
    },
    methods: {
        async fetchData() {
            let r = await api.get(this, api.REPORTS + 'occupancy', {
                date: this.reportDate,
                property_id: this.property_id
            })
            this.reportData = r.report;
        },
        async fetchProperty(id) {
            let r = await api.get(this, api.PROPERTIES + id)
            this.propertyData = r.property;
        }
    }
}
</script>


<style scoped>
.main-container {
  margin-top: 0%;
  margin-bottom: 0%;
  width: 1123px;
  height: auto;
  position: relative;
}
.main-body{
  margin-top: 10px;
}
.header-line-1 {
  font-family: "Gotham";
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  margin: 0;
  line-height: 22px
}
.header-line-2 {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin: 0;
  line-height: 28px;
}
.header-line-3 {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}
.header-line-4 {
  font-family: 'Gotham';
  font-style: normal;
  font-size: 10px;
}
span {
  color: #101318;
}
table {
  width: 100%;
  border: 1px solid #DFE3E8;
}
.space-type {
  border: 1px solid #DFE3E8;
  background: #FFFFFF;
  font-size: 13px;
  font-weight: bold;
  font-family: 'Graphik Web';
  height: 28px;
  padding: 4px 0px 0px 8px;
}
.table-header {
  font-family: 'Graphik Web';
  font-size: 10px;
  height: 35px;
  line-height: 1.5;
  background: #DFE3E8 !important;
}
.table-first-header {
  width: 75px;
  text-align: left;
}
.last-row {
  text-align: left;
  font-weight: 600;
  font-size: 10px;
  background: #DFE3E8 !important;
  border: 1px solid #DCDCDC;
  box-sizing: border-box;
}
th {
  padding-left: 8px;
}
td {
  font-size: 10px;
  padding: 0px 0px 0px 8px;
  line-height: 1;
}
td:first-child {
  font-weight: 500;
}
tr {
  font-family: 'Graphik Web';
  font-style: normal;
  font-weight: 400;
  text-align: left;
  color: #101318;
  height: 26px;
}
tr:nth-child(odd) {
  background-color: #F4F6F8;
}
tr:nth-child(even) {
  background-color: #FFFFFF;
}

.summary-grid > tr {
   background-color: #FFFFFF;
}
.summary-grid > tr > th {
   background-color: #FFFFFF;
}
.facilityBox {
  height: 50px;
  width: 50px;
  background-color: #DFE3E8;
}
</style>
